<template>
  <div id="app">
    <background />
    <!-- <div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
    </div> -->
    <transition-page>
      <router-view />
    </transition-page>
  </div>
</template>

<script>
import Background from "./components/Background";
import TransitionPage from "./components/TransitionPage";

import "vue-swatches/dist/vue-swatches.css";

export default {
  components: {
    TransitionPage,
    Background,
  },
  name: "App",
  data: () => ({
    //
  }),
  computed: {},
  created: function () {},
  mounted: function () {},
  methods: {
    getMobileOperatingSystem() {},
  },
};
</script>


<style lang="scss">
@import "~@/assets/scss/brands.scss";
@import "~@/assets/scss/fontawesome.scss";
@import "~@/assets/scss/light.scss";
@import "~@/assets/scss/regular.scss";
@import "~@/assets/scss/solid.scss";

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_regular-webfont.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_light-webfont.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: "nexa";
  src: url("~@/assets/fonts/nexa_bold-webfont.woff2") format("woff2");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "BigJohn";
  src: url("~@/assets/fonts/BigJohnPRO-Bold.woff2") format("woff2"),
    url("~@/assets/fonts/BigJohnPRO-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  font-family: "nexa";
  // user-select: none;
  // overflow: hidden;
}

body {
  width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background-color: #ff5900;
}
html {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background-color: #ff5900;
}

#app {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // background-color: #ff5900;
}

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
