<template>
  <div class="game" v-if="!loading">
    <div class="infoBtn" @click="detailsModalActive = true">
      <i class="fal fa-question"></i>
    </div>

    <p class="qrBtn" @click="qrModal = true" style=" white-space: nowrap !important;">
      <img src="@/assets/pulpito.svg" height="25px" alt="" srcset="" /> Descargá la App
    </p>

    <!-- modals -->
    <div
      class="termsModal"
      v-if="dataConfig"
      :class="{ modalActive: termsModalActive }"
    >
      <h1 style="font-size: 3vh !important">Término y condiciones</h1>
      <div class="div">
        <p v-for="(i, index) in dataConfig.terms" :key="index">{{ i }}</p>
      </div>
      <div></div>
      <div class="closed" @click="termsModalActive = false">
        <i class="fa fa-times"></i>
      </div>
    </div>

    <div
      class="detailsModal"
      v-if="dataConfig"
      :class="{ modalActive: detailsModalActive }"
    >
      <h1 style="font-size: 3vh !important">{{ dataConfig.name }}</h1>
      <h3>{{ dataConfig.description }}</h3>
      <div></div>
      <div class="closed" @click="detailsModalActive = false">
        <i class="fa fa-times"></i>
      </div>
    </div>

    <div class="errorModal" :class="{ 'errorModal-active': errorModal }">
      <h2 style="font-size: 50px !important">Lo sentimos 😥</h2>
      <h2 style="font-size: 30px !important; color: #212f4f">
        {{ textMessage }}
      </h2>

      <div></div>
      <div class="closed" @click="errorModal = false">
        <i class="fa fa-times"></i>
      </div>
    </div>

    <div class="qrModal" :class="{ 'qrModal-active': qrModal }">
      <img src="@/assets/qr_img.png" alt="" srcset="" />
      <div class="closed" @click="qrModal = false">
        <i class="fa fa-times"></i>
      </div>
    </div>

    <div class="successModal" :class="{ 'success-active': successModal }">
      <h2 style="font-size: 50px !important">¡Felicidades 🥳!</h2>
      <h2 style="font-size: 30px !important; color: #212f4f">
        {{ textMessage }}
      </h2>

      <div></div>
      <div class="closed" @click="successModal = false">
        <i class="fa fa-times"></i>
      </div>
    </div>

    <div class="modal" :class="{ modalActive: modalActive }">
      <div>
        <h2 v-if="currentPrizeWon && currentPrizeWon.title">
          {{ currentPrizeWon.title }}
        </h2>

        <p v-if="currentPrizeWon && currentPrizeWon.subTitle">
          {{ currentPrizeWon.subTitle }}
        </p>
      </div>
      <img
        :src="currentPrizeWon.image.original"
        alt=""
        v-if="currentPrizeWon && !modalInput"
      />
      <div class="number-phone" v-if="modalInput">
        <div class="flag">
          <img
            :src="`${require(`@/Countries/` + Country.flag)}`"
            alt=""
            class="imgInp"
            @click="flag_active = !flag_active"
            srcset=""
          />

          <ul class="flag-list" v-if="flag_active">
            <li
              v-for="(item, index) in Countries"
              :key="index"
              @click="(Country = item), (flag_active = false)"
            >
              <img :src="`${require(`@/Countries/` + item.flag)}`" srcset="" />
              {{ item.name }}
            </li>
          </ul>
        </div>
        <div class="box-input">
          <span>+{{ Country.callingCodes[0] }}</span>
          <input
            type="tel"
            v-model="phone"
            @keydown="isNumber($event)"
            placeholder="0000 0000"
          />
        </div>
      </div>
      <p v-if="currentPrizeWon && currentPrizeWon.description">
        {{ currentPrizeWon.description }}
      </p>
      <!-- <span v-if="currentPrizeWon && currentPrizeWon.winner"
        >Te contactaremos por medio de Whatsapp
        <i class="fab fa-whatsapp"></i> para darte los detalles de la entrega de
        tu premio.</span
      > -->

      <button
        v-if="
          currentPrizeWon && currentPrizeWon.type == 'coupon' && !modalInput
        "
        @click="modalInput = true"
        :class="{ shown: currentPrizeWon }"
      >
        Canjear mi cupón
      </button>
      <button
        v-if="modalInput && currentPrizeWon.type == 'coupon'"
        @click="fnCoupon(currentPrizeWon)"
        :class="{ shown: currentPrizeWon, disabled_btn: btndisabled }"
      >
        Reclamar
      </button>
      <button
        v-if="currentPrizeWon && currentPrizeWon.type == 'promotional'"
        @click="fnPromotional(currentPrizeWon)"
        :class="{ shown: currentPrizeWon, disabled_btn: btndisabled }"
      >
        Reclamar
      </button>
      <div class="closed" @click="redeemPrize">
        <i class="fa fa-times"></i>
      </div>
      <!-- <button class="cancel shown" @click="resetState" style="--tooltip-string: 'Regresar'"><i class="fas fa-redo-alt"></i></button> -->
    </div>

    <!-- final modals -->

    <div class="info" v-if="dataConfig">
      <h1 v-if="dataConfig" :title="dataConfig.name">{{ dataConfig.name }}</h1>
      <span style="color: #fff">{{ dataConfig.description }}</span>
    </div>

    <!-- <div class="toolbox">
      <button @click="triggerRoulette">test</button>
      <button @click="addItem">Add Item</button>
      <button @click="removeItem">Remove Item</button>
      <button @click="toggleModal">Toggle Modal</button>

      <p>activePrizesQty: {{ activePrizesQty }}</p>
      <ul>
        <li v-for="prize in activePrizes" :key="prize.id">{{ prize.icon }}</li>
      </ul>
      <p v-if="currentItemName">Current Item: {{ currentItemName }}</p>
      <p v-if="currentItemStatus">Winner?: {{ currentItemStatus }}</p>
      <p v-if="currentItemStatus">You won: {{ currentPrizeWon }}</p>
    </div> -->
    <!-- <button @click="editItem" class="editPrizes" ><i class="fas fa-lock-alt"></i></button> -->

    <div class="brandLogo">
      <img src="../assets/Dilo-01.svg" alt="" />
    </div>
    <div class="rouletteOuterContainer" v-if="dataConfig">
      <div class="bg"></div>
      <div class="logoContainer">
        <img src="../assets/Dilo-02.svg" />
      </div>
      <div class="pin"></div>

      <div
        class="rouletteContainer"
        ref="roulette"
        :style="{ '--p': activePrizesQty, '--cos': 0.5 }"
      >
        <svg viewBox="-1125 -1125 2250 2250" style="--sw: 10px">
          <circle
            v-for="(prize, index) in activePrizes"
            :key="prize['.key']"
            r="500"
            :stroke="prize.backgroundColor"
            stroke-width="1180"
            :stroke-dasharray="
              `calc(3142 * ${100 / activePrizesQty} / 100) 3142`
            "
            :transform="`rotate(${(360 / activePrizesQty) * index})`"
            :id="prize.id"
          />

          <circle
            v-for="(prize, index) in activePrizes"
            :key="'over-' + prize['.key']"
            r="500"
            :stroke="
              index + 1 == activePrizes.length - currentItem
                ? 'rgba(255,255,255,0.5)'
                : 'rgba(255,255,255,0)'
            "
            stroke-width="1180"
            :stroke-dasharray="
              `calc(3142 * ${100 / activePrizesQty} / 100) 3142`
            "
            :transform="`rotate(${(360 / activePrizesQty) * index})`"
            :id="prize.id"
          />
          <!-- <circle r="500" fill="bisque"
          stroke="tomato"
          stroke-width="1000"
          :stroke-dasharray="`calc(3142 * ${100/prizes.length} / 100) 3142`"
		  />

          <circle r="500" fill="bisque"
          stroke="tomato"
          stroke-width="1000"
          :stroke-dasharray="`calc(3142 * ${100/prizes.length} / 100) 3142`"/> -->

          <!-- <circle class="circ cc" r="1000"></circle> -->
          <!-- <circle class="circ ic" r="1000"></circle> -->
          <!-- <line class="rad cc" x2="1000" style="--i: 0"></line>
          <line class="rad cc" x2="1000" style="--i: 1"></line>
          <line class="rad cc" x2="1000" style="--i: 2"></line>
          <line class="rad cc" x2="1000" style="--i: 3"></line>
          <line class="rad cc" x2="1000" style="--i: 4"></line>
          <line class="rad cc" x2="1000" style="--i: 5"></line>
          <line class="rad cc" x2="1000" style="--i: 6"></line>
          <line class="rad cc" x2="1000" style="--i: 7"></line>
          <line class="rad cc" x2="1000" style="--i: 8"></line> -->
        </svg>

        <div
          class="imgContainer"
          :style="{
            '--m': activePrizesQty,
            '--tan': 0.45,
            '--rot': 360 / activePrizesQty / 2 + 'deg',
          }"
        >
          <p
            :style="{ '--i': index, color: prize.color }"
            v-for="(prize, index) in activePrizes"
            :key="prize.id"
          >
            <!-- <i :class="`fas fa-${prize.icon}`" v-if="prize.icon"></i> -->
            <!-- <i :class="`fal fa-${prize.icon}`" class="icobg" v-if="prize.icon"></i> -->
            <img :src="prize.image.original" v-if="prize.image" />
            <!-- <a>{{prize.name}}</a> -->
          </p>
        </div>
      </div>
    </div>
    <div class="terminos" v-if="dataConfig && dataConfig.terms.length > 0">
      <a @click="termsModalActive = true">Terminos y condiciones</a>
    </div>
    <div class="codeInput" v-if="!dataConfig">
      <input type="text" class="code-sen" v-model="codeInput" />
      <button class="btn" @click="goToCode">ingresar</button>
    </div>
    <div class="footer" v-if="dataConfig">
      <button class="roll" @click="triggerRoulette">¡Girá la Ruleta!</button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import { Propeller } from "Propeller/src/propeller";
import { db, fb } from "@/firebase";
import axios from "axios";
import countries from "../Countries/countries.json";
import country from "../Countries/country.json";
import moment from "moment-timezone";
import "moment/locale/es";

Propeller = window.Propeller; // fix bad npm import

export default {
  name: "Home",
  components: {},
  directives: {},
  data: () => ({
    flag_active: false,
    orders: [],
    qrModal: false,
    successModal: false,
    textMessage: "",
    phone: null,
    loading: false,
    rouletteData: [],
    codeInput: null,
    Countries: countries,
    Country: country,
    modalInput: false,
    prizesData: null,
    btndisabled: false,
    errd: false,
    modalActive: false,
    errorModal: false,
    termsModalActive: false,
    detailsModalActive: false,
    currentItemName: null,
    currentItemStatus: null,
    currentPrizeWon: null,
    currentPrizeIndex: null,
    currentRotatingPrizeIndex: null,
    hideRoll: false,
    user: null,
    prizes: [],
    roulette: null,
    config: null,
  }),
  computed: {
    sortedPrizes() {
      return this.activePrizes.slice().reverse();
    },
    sameDay() {
      if (this.config && this.config.startDate) {
        return moment(this.config.startDate.toDate()).isSame(
          this.config.endDate.toDate(),
          "day"
        );
      } else {
        return false;
      }
    },
    activePrizesQty() {
      return this.prizes.reduce(function(n, prize) {
        return n + (prize.stock != 0);
      }, 0);
    },
    startingDate() {
      let date;
      if (this.config && this.config.startDate) {
        date = moment(this.config.startDate.toDate()).format(
          "D [de] MMMM [del] YYYY"
        );
      } else {
        date = "";
      }
      return date;
    },
    endingDate() {
      let date;
      if (this.config && this.config.endDate) {
        date = moment(this.config.endDate.toDate()).format(
          "D [de] MMMM [del] YYYY"
        );
      } else {
        date = "";
      }
      return date;
    },
    dataConfig() {
      return this.rouletteData[0];
    },
    activePrizes() {
      let activePrizes = this.prizes.filter((prize) => {
        // console.log(prize[".key"]);
        return prize.stock != 0;
      });
      function compare(a, b) {
        if (a.position < b.position) return -1;
        if (a.position > b.position) return 1;
        return 0;
      }

      return activePrizes.sort(compare);
    },
    availableRetries() {
      return this.orders.filter((order) => {
        return !this.config.usedOrders.includes(order[".key"]);
      });
    },
    currentItem() {
      if (this.roulette) {
        return Math.floor(
          Math.abs(this.roulette.angle) / (360 / this.activePrizesQty)
        );
      } else {
        return 0;
      }
    },
  },
  created: function() {},
  async mounted() {
    if (this.$route.params._id) {
      await this.$binding(
        "rouletteData",
        db.collection("roulette").where("code", "==", this.$route.params._id)
      );
      this.$binding(
        "prizes",
        db
          .collection("roulette")
          .doc(this.rouletteData[0][`.key`])
          .collection("prizes")
          .where("active", "==", true)
          .where("deleted", "==", false)
      );
      this.$nextTick(() => {
        this.roulette = new Propeller(this.$refs.roulette, {
          inertia: 0.99,
          minimalSpeed: 0.1,
          speed: 0,
          onStop: () => this.stopped(),
          onRotate: () => this.rotating(),
          onDragStop: () => {
            if (this.roulette.speed < 0.1) {
              this.hideRoll = false;
            }
          },
        });
      });
      // console.debug(this.prizesData);
      // console.debug(this.rouletteData);

      this.loading = true;
    } else {
      // console.debug(this.$route.params._id);
    }

    this.loading = false;
  },
  methods: {
    isNumber: function(evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    async goToCode() {
      if (this.codeInput) {
        await this.$binding(
          "rouletteData",
          db.collection("roulette").where("code", "==", this.codeInput)
        );
        this.$binding(
          "prizes",
          db
            .collection("roulette")
            .doc(this.rouletteData[0][`.key`])
            .collection("prizes")
            .where("active", "==", true)
            .where("deleted", "==", false)
        );
        this.$nextTick(() => {
          this.roulette = new Propeller(this.$refs.roulette, {
            inertia: 0.99,
            minimalSpeed: 0.1,
            speed: 0,
            onStop: () => this.stopped(),
            onRotate: () => this.rotating(),
            onDragStop: () => {
              if (this.roulette.speed < 0.1) {
                this.hideRoll = false;
              }
            },
          });
        });
      }
    },
    onNext() {
      this.$router.push({ path: "/" });
    },
    editItem() {
      let pass = prompt("Ingrese la contraseña administrativa", "");
      if (pass != null) {
        if (pass.toLowerCase() == "fury" || pass == "42") {
          this.$router.push({ path: "/edit" });
        } else {
          alert("Contraseña incorrecta!");
        }
      }
    },
    stopped() {
      // console.log("stopped");
      this.currentPrizeWon = this.sortedPrizes[this.currentItem];
      // console.debug(this.currentPrizeWon);
      this.toggleModal(this.currentPrizeWon);
    },
    fnCoupon(item) {
      this.btndisabled = true;
      if (item.manageInventory && this.phone) {
        var code = this.Country.callingCodes[0];
        var phoneArea = code + this.phone;
        var config = {
          method: "post",
          url:
            "https://us-central1-el-ocho.cloudfunctions.net/httpRouletteAddCoupon",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            rouletteId: this.rouletteData[0][`.key`],
            prizeId: item[`.key`],
            couponCode: item.target.coupon,
            phone: phoneArea,
          },
        };

        axios(config)
          .then((response) => {
            // console.log(response);
            this.textMessage = 'Revisá tu cuponera para ver tu premio.'
            
            setTimeout(() => {
              this.successActions();
            }, 2000);
          })
          .catch((err) => {
            this.textMessage = err.response.data.message
              ? err.response.data.message
              : "Ocurrio un problema o el cúpon ya fue agregado";
            this.errorActions();
          });
      } else {
        this.textMessage = "Ingresa tu número";
        this.errorActions();
      }
    },
    fnPromotional(item) {
      this.btndisabled = true;
      if (item.manageInventory) {
        var config = {
          method: "post",
          url:
            "https://us-central1-el-ocho.cloudfunctions.net/httpRouletteClaim",
          headers: {
            "Content-Type": "application/json",
          },
          data: {
            rouletteId: this.rouletteData[0][`.key`],
            prizeId: item[`.key`],
          },
        };

        axios(config)
          .then((response) => {
            this.textMessage = response.data.message
              ? response.data.message
              : "tu premio reclamado con éxito";
            setTimeout(() => {
              this.successActions();
            }, 2000);
          })
          .catch((err) => {
            this.textMessage = err.response.data.messagee
              ? (this.textMessage = err.response.data.message)
              : "Ocurrio un error";
            this.errorActions();
          });
      } else {
        setTimeout(() => {
          this.modalActive = false;
          this.textMessage = "Reclama tu premio";
          this.successActions();
        }, 3000);
      }
    },
    rotating() {
      this.hideRoll = true;
      // console.log("Algo 2");
    },
    triggerRoulette() {
      var randSpeed = Math.floor(Math.random() * (500 - 200 + 1) + 200);
      // var randSpeed = 200;
      // console.debug("randSpeed", randSpeed);
      this.roulette.speed = randSpeed;
      this.currentItemStatus = null;
      this.currentItemName = null;
    },
    addItem() {
      let currentRandom = Math.floor(Math.random() * 10000);
      let currentRandomItem = Math.floor(Math.random() * (4 - 0) + 0);
      let items = [
        {
          id: "premio 6",
          name: "premio 6",
          description: "Premio de prueba",
          image: "../assets/logo.png",
          icon: "fal fa-watch",
          color: "#ffffff",
          backgroundColor: "#00908a",
          winner: true,
          stock: 1,
        },
        {
          id: "premio 7",
          name: "premio 7",
          description: "Premio de prueba",
          image: "../assets/logo.png",
          icon: "fal fa-cookie-bite",
          color: "#ffffff",
          backgroundColor: "#41024f",
          winner: true,
          stock: 1,
        },
        {
          id: "premio 8",
          name: "premio 8",
          description: "Premio de prueba",
          image: "../assets/logo.png",
          icon: "fal fa-headphones-alt",
          color: "#ffffff",
          backgroundColor: "#f60c61",
          winner: true,
          stock: 1,
        },
        {
          id: "premio 9",
          name: "premio 9",
          description: "Premio de prueba",
          image: "../assets/logo.png",
          icon: "fal fa-laptop",
          color: "#ffffff",
          backgroundColor: "#ff6a00",
          winner: true,
          stock: 1,
        },
      ];
      let item = items[currentRandomItem];
      // console.log(currentRandomItem);
      item.id = `premio 6 ${currentRandom}`;
      item.name = `premio 6 ${currentRandom}`;

      this.prizes.push(item);
    },
    removeItem() {
      this.prizes.pop();
    },
    toggleModal(item) {
      switch (item.type) {
        case "coupon":
          this.modalActive = !this.modalActive;

          break;
        case "promotional":
          this.modalActive = !this.modalActive;

          break;

        default:
          this.modalActive = !this.modalActive;
          break;
      }
    },
    successActions() {
      this.successModal = true;
      this.modalActive = false;
      this.btndisabled = false;
      this.phone = null;
      this.modalInput = false;
      this.errorModal = false;
    },
    errorActions() {
      this.successModal = false;
      this.modalActive = false;
      this.btndisabled = false;
      this.phone = null;
      this.modalInput = false;
      this.errorModal = true;
    },
    resetState() {
      this.modalActive = false;
      this.hideRoll = false;
      this.currentItemStatus = null;
      this.currentPrizeWon = null;
    },
    redeemPrize() {
      this.modalActive = false;
      this.hideRoll = false;
      this.btndisabled = false;
      this.phone = null;
      this.modalInput = false;
      this.currentItemStatus = null;
      this.currentPrizeWon = null;
    },
  },
  firestore() {
    return {};
  },
};
</script>

<style lang="scss">
.codeInput {
  margin-top: 40vh;
  background-color: #fff;
  border-radius: 2vh;
  width: 30vh;
  display: flex;
  padding: 3vh;
  flex-direction: column;
  .code-sen {
    height: 50px;
    width: auto;
    background-color: #f2f2f2;
    border-radius: 2vh;
    font-size: 30px;
    padding: 0px 20px;
    text-align: center;
  }
  .btn {
    height: 50px;
    background-color: #FF1744;
    margin-top: 10px;
    border-radius: 2vh;
    font-size: 20px;
    color: #fff;
  }
}
@mixin modals-desing {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  left: 50vw;
  top: 50vh;
  width: 0vh;
  height: 0vh;
  margin-left: -0vh;
  margin-top: -0vh;
  border-radius: 3vh;
  color: #FF1744;
  background-color: #ffffff;
  border: none;

  //   backdrop-filter: blur(80px);
  z-index: 9999;
  text-align: center;
  padding: 0vh;
  box-sizing: border-box;
  transition: 0.3s all;
}
@mixin btn-dialog {
  opacity: 0;
  // height: 0vh;
  line-height: 0vh;
  border-radius: 10vh;
  background-color: #FF1744;
  color: #fff;
  font-size: 0vh;
  position: relative;
  font-weight: bold;
  outline: none;
  font-family: "BigJohn";
  font-weight: bold;
  text-transform: uppercase;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.2vw;
}
@mixin btn-dialog-shuw {
  opacity: 1;
  transition: 0.3s ease-in-out;
  width: auto;
  display: block;
  background-color: #FF1744;
  color: #fff;
  border-radius: 3vh;
  font-size: 4vh;
  line-height: 2vh;
  padding: 3vh;
  letter-spacing: 1px;
  box-shadow: 0px 10px 30px 0 rgba(0, 0, 0, 0.199);
  cursor: pointer;
  &:hover {
    box-shadow: 0px 0px 0px 0 rgba(0, 0, 0, 0);
    background-color: #ff7931;
  }
}

$c0: #ff7a18, #af002d, #319197;
$c1: #00908a, #41024f, #f60c61, #ff6a00, #ffb231;
$cd: #170e27;
$cm: #434343;
$cl: #d4d4d4;

$track-p: Min(0.5em, 2vw);
$track-u: 2.125em;
$track-h: 0.25em;
$track-r: 0.5 * $track-h;

$thumb-d: 1em;
$thumb-r: 0.5 * $thumb-d;

$arrow-a: 70deg;
$arrow-b: 0.25em;
$arrow-r: 0.25em;

$label-d: 3ch;
$label-o: -0.5 * $label-d;

$t: 0.3s;

*,
::after {
  box-sizing: inherit;
  margin: 0;
  border: none;
  padding: 0;
  background: transparent;
  color: inherit;
}
.number-phone {
  width: 500px !important;
  height: auto;
  background-color: #f2f2f2;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  .box-input {
    height: 80px;
    width: calc(100% - 15%) !important;
    background-color: #fff;
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 27px !important;
      margin-left: 20px;
      color: #9c9c9c !important;
    }
    input {
      font-size: 40px;
      padding-left: 10px;
      font-weight: 600;
      line-height: 0px !important;
      width: 100%;
      outline: none;
      letter-spacing: 3px;
      &:focus {
        outline: none;
      }
    }
  }
  .flag {
    height: 80px;
    width: calc(100% - 80%) !important;
    font-size: 6vh;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    .imgInp {
      width: 100% !important;

      border-radius: 15px !important;
    }
    ul {
      top: 10vh;
      left: 0;
      position: absolute;
      height: 400px;
      width: 400px;
      overflow: scroll;
      background-color: #eee;
      border-radius: 2vh;
      padding: 2vh;
      box-shadow: 0px 20px 50px 0 rgba(0, 0, 0, 0.3);
      z-index: 9999;
      li {
        list-style: none;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        width: 100%;
        height: 50px !important;
        border-bottom: 1px solid rgb(162, 162, 162);
        font-size: 18px !important;
        &:hover {
          background-color: rgb(217, 217, 217);
        }
        img {
          margin-right: 10px;
          max-height: 30px !important;
          border-radius: 10px;
        }
      }
    }
  }
}
.closed {
  background-color: #170e27;
  width: 6vh !important;
  height: 6vh;
  border-radius: 50%;
  position: absolute !important;
  right: 2vh;
  top: 2vh;
  font-size: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
.game {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: transparent;
  background-image: url("../assets/back-03.png");
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center !important;

  // background: radial-gradient(#ffffff00, #fff);

  .brandLogo {
    position: absolute;
    top: 2vh;
    left: 2vh;
    width: 20vh;
  }

  .termsModal,
  .detailsModal,
  .successModal,
  .qrModal,
  .errorModal {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    color: #FF1744;
    background-color: #ffffff;
    border: none;

    //   backdrop-filter: blur(80px);
    z-index: 9999;
    text-align: center;

    box-sizing: border-box;
    transition: 0.3s all;
    overflow: hidden;

    left: 5vw;
    top: 150vh;
    width: 90vw;
    height: calc(90vh - 30px);
    border-radius: 5vh;
    color: #FF1744;
    border: none;
    //   background-color: rgba(40, 40, 40, 0.3);
    //   backdrop-filter: blur(80px);
    z-index: 9999;
    text-align: center;
    padding: 5vh;
    box-sizing: border-box;

    .ordersContainer {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      flex-shrink: 1;
      overflow: hidden;
      overflow-y: scroll;

      p {
        flex-shrink: 0;
        font-size: 12px;
        font-style: italic;
        color: #666;
      }

      div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        margin: 5px 0;
        padding-bottom: 5px;
        border-bottom: 1px solid #eee;
        width: 100%;
        &.disabled {
          * {
            opacity: 0.3;
          }
          filter: grayscale(1);
        }
        img {
          height: 36px;
          width: 36px;
          margin: 0 5px;
          border-radius: 18px;
          flex-shrink: 0;
        }
        span {
          text-align: left;
          margin-left: 5px;

          b {
            color: #333;
          }
        }
        span:first-child {
          width: 28px;
          flex-shrink: 0;
          text-align: center;
          margin: 0;
          // background-color: red;
        }
      }
    }
    button {
      opacity: 0;
      width: 0vh;
      // height: 0vh;
      line-height: 0vh;
      border-radius: 10vh;
      background-color: #ffffff;
      color: #FF1744;
      font-size: 0vh;
      position: relative;
      font-weight: bold;
      outline: none;
      background-color: #ffffff;
      font-family: "BigJohn";
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      text-align: center;
      letter-spacing: -0.2vw;
    }

    p {
      opacity: 0;
      font-size: 12px;
      color: #333333;
      // height: 70%;
      overflow: hidden;
      overflow-y: scroll;
      flex-shrink: 1;
      text-align: justify;
      padding: 5vw;
      white-space: pre-line;
      // line-break: anywhere;
    }
    &.errorModal-active {
      left: 25vw !important;
      top: 20vh !important;
      width: 50vw !important;
      box-shadow: 0px 0px 500px 489px #0000003e !important;
      height: calc(60vh - 30px) !important;
      z-index: 999999 !important;
    }
    &.success-active {
      left: 25vw !important;
      top: 20vh !important;
      width: 50vw !important;
      box-shadow: 0px 0px 500px 489px #00000021 !important;
      height: calc(60vh - 30px) !important;
      z-index: 999999 !important;
    }
    &.qrModal-active {
      left: 32vw !important;
      top: 27vh !important;
      width: 50vh !important;
      box-shadow: 0px 0px 500px 489px #0000002a !important;
      height: 50vh !important;
      z-index: 999999 !important;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 100% !important;
      }
    }
    &.modalActive {
      top: calc((10vh + 30px + 36px) / 2);

      div,
      p {
        opacity: 1;
        transition: 0.3s 0.4s opacity;
      }

      button {
        margin-top: 10px;
        opacity: 1;
        transition: 0.5s 0.5s opacity;
        width: 40%;
        flex-shrink: 0;
        display: block;
        border-radius: 2vh;
        font-size: 3vw;
        padding: 0px;
        line-height: 3vw;
        box-shadow: 0px 20px 50px 0 rgba(0, 0, 0, 0.3);
      }
    }
  }

  .modal {
    @include modals-desing;

    div {
      opacity: 0;
      position: relative;
      width: 100%;
    }

    button {
      @include btn-dialog;
    }

    h2 {
      font-size: 5vh;

      line-height: 6vh;
      font-family: "BigJohn";
      font-weight: bold;
      text-transform: uppercase;
    }

    h3 {
      margin-top: 10px;
      font-size: 2vw;
      color: #ef4438;

      line-height: 2vw;
      font-family: "BigJohn";
      font-weight: bold;
      text-transform: uppercase;
    }

    p {
      opacity: 0;
      font-size: 3vw;
      color: #170e27;

      &:nth-child(3) {
        padding: 1vw;
        font-size: 4.5vw;
        font-weight: bold;
        line-height: 4.5vw;
        color: #FF1744;
      }
    }

    span {
      opacity: 0;
      color: #333333;
    }

    img {
      height: auto;
      width: auto;
      margin: 0px;
      max-height: 200px;
      // flex-shrink: 10;
      object-fit: contain;
      // max-width: 50vh;
    }

    &.modalActive {
      left: 5vw;
      top: calc((10vh + 30px + 36px) / 2);
      width: 90vw;
      height: calc(90vh - 30px - 36px);
      border-radius: 5vh;
      color: #FF1744;
      border: none;
      //   background-color: rgba(40, 40, 40, 0.3);
      //   backdrop-filter: blur(80px);
      z-index: 9999;
      text-align: center;
      padding: 5vw;
      box-sizing: border-box;

      div,
      p {
        opacity: 1;
        transition: 0.3s 0.4s opacity;
      }

      span {
        opacity: 1;
        transition: 0.3s 0.4s opacity;
      }

      button.shown {
        @include btn-dialog-shuw;
      }

      //   & *{
      // 	  opacity: 1;
      // 	  transition: 0.5s 1s opacity;
      //   }
    }
  }
  .modal-input {
    @include modals-desing;
    button {
      opacity: 0;
      // height: 0vh;
      line-height: 0vh;
      border-radius: 10vh;
      background-color: #FF1744;
      color: #fff;
      font-size: 0vh;
      position: relative;
      font-weight: bold;
      outline: none;
      font-family: "BigJohn";
      font-weight: bold;
      text-transform: uppercase;
      white-space: nowrap;
      text-align: center;
      letter-spacing: -0.2vw;
    }

    h2 {
      margin-top: 20px;
      font-size: 5vw;

      line-height: 8vw;
      font-family: "BigJohn";
      font-weight: bold;
      text-transform: uppercase;
    }

    h3 {
      margin-top: 10px;
      font-size: 2vw;
      color: #ef4438;

      line-height: 2vw;
      font-family: "BigJohn";
      font-weight: bold;
      text-transform: uppercase;
    }

    p {
      opacity: 0;
      font-size: 3vw;
      color: #170e27;

      &:nth-child(3) {
        padding: 1vw;
        font-size: 4.5vw;
        font-weight: bold;
        line-height: 4.5vw;
        color: #FF1744;
      }
    }

    span {
      opacity: 0;
      color: #333333;
    }

    img {
      height: auto;
      width: auto;
      margin: 0px;
      max-height: 200px;
      // flex-shrink: 10;
      object-fit: contain;
      // max-width: 50vh;
    }

    &.modalActive {
      left: 5vw;
      top: calc((10vh + 30px + 36px) / 2);
      width: 90vw;
      height: calc(90vh - 30px - 36px);
      border-radius: 5vh;
      color: #FF1744;
      border: none;
      //   background-color: rgba(40, 40, 40, 0.3);
      //   backdrop-filter: blur(80px);
      z-index: 9999;
      text-align: center;
      padding: 5vw;
      box-sizing: border-box;

      div,
      p {
        opacity: 1;
        transition: 0.3s 0.4s opacity;
      }

      span {
        opacity: 1;
        transition: 0.3s 0.4s opacity;
      }

      button.shown {
        @include btn-dialog-shuw;
      }

      //   & *{
      // 	  opacity: 1;
      // 	  transition: 0.5s 1s opacity;
      //   }
    }
  }
}

.rouletteOuterContainer {
  position: relative;
  height: 60vh;
  width: 60vh;
  transform: rotate(-90deg);

  .rouletteContainer {
    position: relative;
    z-index: 10;
  }

  .logoContainer {
    background-color: #FF1744;
    width: 13vh;
    height: 13vh;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -6.5vh;
    margin-left: -6.5vh;
    z-index: 120;
    box-shadow: 0 30px 50px 0 rgb(0 0 0 / 30%);
    transform: rotate(90deg);

    img {
      // position: absolute;
      width: 9vh;
      margin: 2vh;
      //   margin: 20px;
    }

    svg {
      width: 180px;
      margin: 60px;

      .cls-1 {
        fill: #ffffff;
      }
    }
  }

  .bg {
    // background-color: #FF1744;
    opacity: 1;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    box-shadow: -30px 0 50px 0 rgba(0, 0, 0, 0.3);
  }

  .pin {
    width: 7vh;
    height: 7vh;
    border-radius: 1vw;
    background-color: #FF1744;
    transform: rotate(45deg);
    position: absolute;
    top: 50%;
    right: 50%;
    margin-right: -7.8vh;
    margin-top: -3.5vh;
    z-index: 100;
  }
}

.rouletteContainer {
  --i: var(--narr, 1);
  --not-i: calc(1 - var(--i));
  --j: var(--wide, 1);
  --not-j: calc(1 - var(--j));
  --c: #{nth($c0, 1)};
  //   display: grid;
  position: relative;
  overflow: hidden;
  // pointer-events: none;
  cursor: -webkit-grabbing;
  cursor: grabbing;
  //   grid-template-rows: max-content 1fr;
  height: 60vh;
  width: 60vh;

  font: 400 clamp(0.875em, 2.25vw, 1.5em) / 1.375 raleway, sans-serif;
}

output {
  grid-area: calc(1 + var(--i) + var(--not-j)) /
    calc(1 + 2 * var(--k) * var(--not-i) + var(--not-k));
  justify-self: start;
  margin: calc(var(--js) * (var(--i) *#{$track-p} + var(--not-i) *#{-$arrow-b}))
    0 calc(var(--js) * var(--not-i) *#{-$arrow-b})
    calc(var(--js) * var(--not-i) *#{$track-p});
  min-width: 2em;
  border-radius: $arrow-r;
  transform: translate(calc(var(--i) * (var(--pos) - 50%)));
  background: rgba(nth($c0, 1), var(--k));
  text-align: center;

  &::after {
    content: counter(p);
  }

  @supports (background: conic-gradient(red, tan)) {
    .js & {
      --xy: calc(var(--i) * 50%) calc(var(--not-i) * 50%);
      margin-top: calc(var(--not-i) *#{-$arrow-b} + var(--i) *#{$track-p});
      border: solid $arrow-b transparent;
      border-radius: $arrow-b + $arrow-r;
      --mask: linear-gradient(red, red) padding-box,
        conic-gradient(
            from calc(90deg - 0.5 *#{$arrow-a} + var(--i) * 90deg) at var(--xy),
            red #{$arrow-a},
            transparent 0%
          )
          var(--xy) / 50% 50% no-repeat border-box;
      -webkit-mask: var(--mask);
      mask: var(--mask);
    }
  }
}

svg {
  // place-self: stretch;
  width: 100%;
  fill: none;
  position: relative;
  z-index: 10;

  // height: 100%;
  // stroke-width: var(--sw)
}

.ic {
  --mc: var(--ic);
  --mf: var(--cos);
  --gc: #{nth($c0, 3)};
}

.cc {
  --mc: var(--cc);
  --mf: 1;
  --gc: #{nth($c0, 2)};
}

// .circ, .rad { stroke: var(--gc) }

.circ {
  opacity: var(--mc);

  &.ic {
    transform: scale(var(--cos));
    stroke-width: 1900;
    stroke-dasharray: 1390;
    stroke-dashoffset: 1000;
    // stroke-miterlimit: 20;
    // stroke-linecap: round;
  }
}

[id="im"] circle {
  transform: scalex(calc(1 / var(--cos)));
}

line {
  transform: rotate(calc((var(--i) + var(--o, 0)) / var(--p) * 1turn))
    scalex(var(--mf));
  stroke-width: calc(0.5 * var(--sw));
  stroke-dasharray: calc(2 * var(--sw));
  opacity: calc(var(--mc) * (var(--p) - var(--i)));
  marker-end: url(#cm);

  &.ic {
    --o: 0.5;
    marker-end: url(#im);
  }
}

.o {
  --gc: #{nth($c1, 5)};
  opacity: calc(var(--cc) + var(--ic));
}

.imgContainer {
  top: 0;
  left: 0;
  --d: 17vh; /* image size */
  --rel: 0.7; /* how much extra space we want between images, 1 = one image size */
  --r: calc(0.3 * (1 + var(--rel)) * var(--d) / var(--tan)); /* circle radius */
  --s: 60vh; /* container size */
  position: absolute;
  z-index: 20;
  width: var(--s);
  height: var(--s);
  transform: rotate(var(--rot));
  overflow: hidden;

  // background: silver
}

.imgContainer p {
  position: absolute;
  display: inline-block;
  top: 50%;
  left: 50%;

  font-size: calc(var(--d) - 2vw);
  line-height: var(--d);

  // width: var(--d);
  text-align: center;
  vertical-align: bottom;
  margin: calc(-0.5 * var(--d));
  width: var(--d);
  height: var(--d);
  --az: calc(var(--i) * 1turn / var(--m));
  transform: rotate(var(--az)) translate(var(--r)) rotate(90deg);
  // rotate(calc(-1*var(--az)))

  a {
    font-size: 14px;
    position: absolute;
    top: -9vw;
    left: calc((var(--d) / 2) * -1);
    //   left: 50%;
    //   transform: rotate(60deg) ;
    width: calc(var(--d) * 2);
    height: 32px;
    line-height: 14px;
    text-align: center;
    z-index: 20;
  }

  img {
    width: 100%;
  }

  i.icobg {
    text-align: center;
    color: #000000;
    opacity: 0.1;
    transform: translateY(-12vw) scale(1.7);
    z-index: 1;
  }

  i {
    position: relative;
    z-index: 10;
    // font-size: var(--d);
  }
}

img {
  max-width: 100%;
}

.toolbox {
  box-sizing: border-box;
  background-color: rgba(40, 40, 40, 0.9);
  //   backdrop-filter: blur(80px);
  border-radius: 15px;
  width: 300px;
  padding: 25px;
  color: white;
  //   height: 200px;
  position: absolute;
  left: 10px;
  bottom: 10px;
  z-index: 99999;

  button {
    background-color: #FF1744;
    border-radius: 5px;
    padding: 15px;
    margin: 15px 0;
    display: block;
    width: 100%;
    // font-weight: 900;
    font-size: 16px;
    outline: none;
  }
}

.editPrizes {
  position: absolute;
  background: none;
  outline: none;
  border: none;
  color: white;
  font-size: 3vw;
  bottom: 2vw;
  left: 2vw;
}

.footer {
  position: relative;
  margin-bottom: 4vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: auto;

  .roll {
    background-color: #fff;
    border-radius: 2vh;
    border: none;
    border-right: none;
    width: auto;
    height: auto;
    padding: 4vh;
    outline: none;
    color: #FF1744;
    font-size: 3vh;
    font-weight: bold;
    z-index: 9999;
    cursor: pointer;
    line-height: 0vw;
    font-family: "BigJohn";
    text-transform: uppercase;
    white-space: nowrap;
    text-align: center;
    letter-spacing: 0vw;
    transition: 0.3s transform;
    box-shadow: 0px 20px 50px 0 rgb(0 0 0 / 30%);
    position: relative;
    z-index: 10;
    transition: 0.3s ease-in-out;
    &.hidden {
      z-index: 1010;
      transform: translateY(40vw);
    }
    &:hover {
      box-shadow: none;
      color: #fe7226;
    }
  }

  .NoTries {
    font-size: 15px;
    line-height: 15px;
    padding: 1vw 0;
    text-align: center;
    outline: none;
    color: #111111;
    font-weight: bold;
    z-index: 9999;
    border-radius: 1vw;
    border: 2px dashed #FF1744;
    // transform: rotate(-20deg) translateX(10vw);
    // left: 10vw;
    position: relative;
    z-index: 10;
  }

  a {
    color: rgba(0, 0, 0, 0.75);
    text-decoration: none;
    z-index: 1000;
    position: relative;
    margin-top: 10px;
  }
}

.info {
  position: relative;
  text-align: center;
  margin-top: 3vw;
  color: #fff !important;
  h1 {
    color: #fff !important;
  }
}

h1 {
  position: relative;
  font-size: 6vh;
  line-height: 6vw;
  text-align: center;
  font-weight: bold;
  color: #FF1744;
  font-family: "BigJohn";
  text-transform: uppercase;
  margin-bottom: 10px;

  // &::after {
  //   content: attr(title);
  //   -webkit-text-fill-color: rgba(
  //     0,
  //     0,
  //     0,
  //     0
  //   ); /* Will override color (regardless of order) */
  //   -webkit-text-stroke-width: 1px;
  //   -webkit-text-stroke-color: rgba(0, 0, 0, 0.75);
  //   position: absolute;
  //   top: 5px;
  //   left: 10px;
  //   z-index: -1;
  // }
}

.topBar {
  // background-color: #af002d;
  width: calc(100% - 134px);
  height: 36px;
  margin: 15px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.infoBtn {
  position: absolute;
  height: 5vh;
  width: 5vh;
  border-radius: 18px;
  color: #FF1744;
  background-color: white;
  right: 3vh;
  top: 3vh;
  box-shadow: 0px 10px 20px #00000029;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3vh;
}
.qrBtn {
  position: absolute;
  height: auto;
  width: auto;
  border-radius: 18px;
  color: #040404;
  background-color: white;
  left: 3vh;
  cursor: pointer;
  bottom: 3vh;
  box-shadow: 0px 10px 20px #00000029;
  display: flex;
  padding: 2vh;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
  font-weight: 900;
  img {
    margin-right: 15px;
  }
}
.disabled_btn {
  pointer-events: none !important;
  opacity: 0.5 !important;
}
.terminos {
  position: fixed;
  right: 2vh;
  bottom: 2vh;
  color: #fff;
  cursor: pointer;
}
</style>
