<template>
  <div class="hello">
        <div class="background"></div>
  </div>
</template>

<script>
export default {
  name: 'Background',
  props: {
    msg: String
  }
}
</script>

<style lang="scss">
  .background{
    position: fixed;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
  // background-image: radial-gradient(circle at 100% 50%, #ffb700, #ff5900) !important;
  background-image: radial-gradient(circle at 100% 50%, #ff5900, #FF1744) !important;

    &.picture{
      object-fit: cover;
      object-position: center;
    }
  }



@keyframes Gradient {
	0% {
		background-position: 0% 50%
	}
	50% {
		background-position: 100% 50%
	}
	100% {
		background-position: 0% 50%
	}
}




</style>
