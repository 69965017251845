import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueFontAwesomePicker from "vfa-picker";

import './firebase';
import firebase from 'firebase/app';
import VueFirestore from 'vue-firestore';

Vue.use(VueFirestore)
Vue.use(firebase)
Vue.use(VueFontAwesomePicker);

Object.defineProperty(Vue.prototype, "firebase", {
  get() {
      return this.$root.firebase;
  }
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
