import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import store from '../store'

Vue.use(VueRouter)

const waitForStorageToBeReady = async (to, from, next) => {
  await store.restored
  next()
}

const routes = [
  {
    path: '/:_id',
    name: 'Home',
    props:true,
    component: Home
  },
  {
    path :'*',
    props:false,
    component:Home
  },
  {
    path: '/edit',
    name: 'Edit',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Edit" */ '../views/Edit.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(waitForStorageToBeReady)

export default router
