import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/firebase-storage';
// import * as geofirex from 'geofirex';

// var config = {
//     apiKey: "AIzaSyCPGZq8_y7nDAfYPyR0CREhmJB0ZRB2PAk",
//     authDomain: "onboarding-basa.firebaseapp.com",
//     databaseURL: "https://onboarding-basa.firebaseio.com",
//     projectId: "onboarding-basa",
//     storageBucket: "onboarding-basa.appspot.com",
//     messagingSenderId: "993811386406",
//     appId: "1:993811386406:web:c8a38888e309f554fe96e7"
// }

// var config = {
//     apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
//     authDomain: "el-ocho.firebaseapp.com",
//     databaseURL: "https://el-ocho.firebaseio.com",
//     projectId: "el-ocho",
//     storageBucket: "el-ocho.appspot.com",
//     messagingSenderId: "770366666625"
// }

let env = process.env.NODE_ENV;


const config = {
    apiKey: "AIzaSyDwqeEX-PH5IV9pMboDUSgz0g6nWAoTTho",
    authDomain: "el-ocho.firebaseapp.com",
    databaseURL: "https://el-ocho.firebaseio.com",
    projectId: "el-ocho",
    storageBucket: "el-ocho.appspot.com",
    messagingSenderId: "770366666625",
    appId: "1:770366666625:web:4f0b735747d8dc789b1a80",
    measurementId: "G-YKP3BFGPT4"
  };
  
const config_dev = {
  apiKey: "AIzaSyBE4DJkIv5Q9GLMr_N_GNTb_CmwHtune1A",
  authDomain: "el-ocho-dev.firebaseapp.com",
  projectId: "el-ocho-dev",
  storageBucket: "el-ocho-dev.appspot.com",
  messagingSenderId: "86761917155",
  appId: "1:86761917155:web:d610a1265e44cde0b9d3d6",
  measurementId: "G-TNFM367VP3"
  };


// Initialize Firebase.
firebase.initializeApp(env == "production" ? config : config_dev);
firebase.auth().languageCode = "es";
// const geofire = geofirex.init(firebase);

export const db = firebase.firestore();
export const fb = firebase;
// export const geo = geofire;