import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import localforage from 'localforage'

Vue.use(Vuex)

// const prizeImages = require('./images.js')

const vuexPersist = new VuexPersistence({
    key: 'rouletteFuryTest',
    storage: localforage,
    asyncStorage: true,
    // modules: ['bp', 'client', 'metadata']
    modules: ['prizes']
})

export default new Vuex.Store({
  state: {
    prizes:[
      {
        id: "premio 1",
        name: "Fury Mean Green",
		    title: "¡Felicidades!",
		    subTitle: "has ganado",
        description: "Una Fury Mean Green",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/1uYal8pIWhQDObCfzZ01%2Fimage-original.jpg?alt=media&token=92f1b411-a6cd-424a-93e4-cef1b6c9e6c9",
        // icon: "fal fa-wine-bottle",
        color: "#ffffff",
        backgroundColor: "#FFB5B5",
        winner: true,
        stock: 1,
      },
      {
        id: "premio 2",
		title: "¡Aww!",
		subTitle: "No has ganado",
        name: "Nada",
        description: "Intenta participar nuevamente",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/4shT9gLLcAZ5XXp4g8nx%2Fimage-original.jpg?alt=media&token=ab040515-5084-491b-8b23-0cd15f42229a",
        // icon: "fal fa-frown",
        color: "#ffffff",
        backgroundColor: "#BF87E2",
        winner: false,
        stock: 1,
      },
      {
        id: "premio 3",
		title: "¡Felicidades!",
		subTitle: "has ganado",
        name: "Camiseta",
        description: "Camiseta",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/tbAt6ifoZBdBy2sOwdZF%2Fimage-original.jpg?alt=media&token=90adc167-3375-4c89-92e9-620fb0bf0f4d",
        // icon: "fal fa-tshirt",
        color: "#ffffff",
        backgroundColor: "#48D0FF",
        winner: true,
        stock: 3,
      },
      {
        id: "premio 4",
		title: "¡Woohoo!",
		subTitle: "Tienes otra oportunidad",
        name: "Intentalo de Nuevo",
        description: "Intentalo de nuevo",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/NqYvMEEBs47HgX3Qk3S6%2Fimage-original.jpg?alt=media&token=6765b6ae-a760-47e3-9900-09b4a78d0587",
        // icon: "fal fa-redo-alt",
        color: "#ffffff",
        backgroundColor: "#ECE456",
        winner: false,
        stock: 1,
      },
      {
        id: "premio 5",
		title: "¡Felicidades!",
		subTitle: "has ganado",
        name: "Paquete de Internet",
        description: "Paquete de Internet",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/ROBUvAuUbLX68rqFaNaI%2Fimage-original.jpg?alt=media&token=020b427b-c355-493d-9ea6-dfcef85630e7",
        // icon: "fal fa-mobile",
        color: "#ffffff",
        backgroundColor: "#CBCADD",
        winner: true,
        stock: 3,
      },
      {
        id: "premio 6",
        title: "¡Woohoo!",
        subTitle: "Tienes otra oportunidad",
        name: "Intentalo de Nuevo",
        description: "Intentalo de nuevo",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/UKh5EnC1D3wPRZAyBCii%2Fimage-original.jpg?alt=media&token=6f894ad5-5bb3-4fac-80c0-07afe1d2c9f7",
        // icon: "fal fa-redo-alt",
        color: "#ffffff",
        backgroundColor: "#A9B5FF",
        winner: false,
        stock: 1,
      }
    ],
    defaultPrizes:[
      {
        id: "premio 1",
        name: "Fury Mean Green",
		    title: "¡Felicidades!",
		    subTitle: "has ganado",
        description: "Una Fury Mean Green",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/YbPu9DQ7Y516gFiuJMp8%2Fimage-original.jpg?alt=media&token=6a24d80d-18fe-40d6-8afe-00f37f7aabb4",
        // icon: "fal fa-wine-bottle",
        color: "#ffffff",
        backgroundColor: "#5170b5",
        winner: true,
        stock: 1,
      },
      {
        id: "premio 2",
		title: "¡Aww!",
		subTitle: "No has ganado",
        name: "Nada",
        description: "Intenta participar nuevamente",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/beXGRoly0qgMyevoSKEl%2Fimage-original.jpg?alt=media&token=77941a6b-8172-467e-b245-ec9745f2de9a",
        // icon: "fal fa-frown",
        color: "#ffffff",
        backgroundColor: "#222222",
        winner: false,
        stock: 1,
      },
      {
        id: "premio 3",
		title: "¡Felicidades!",
		subTitle: "has ganado",
        name: "Camiseta",
        description: "Una Camiseta",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/emJy1QsZOCjFPr5UJbTU%2Fimage-original.jpg?alt=media&token=83be2d2d-33cf-4b27-b6cf-56e4e9503a6d",
        // icon: "fal fa-tshirt",
        color: "#ffffff",
        backgroundColor: "#5170b5",
        winner: true,
        stock: 3,
      },
      {
        id: "premio 4",
		title: "¡Woohoo!",
		subTitle: "Tienes otra oportunidad",
        name: "Intentalo de Nuevo",
        description: "Intentalo de nuevo",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/iKQcajdn0oe6chlUEGIH%2Fimage-original.jpg?alt=media&token=67f3e7f0-e608-49b4-a51e-2909239e1252",
        // icon: "fal fa-redo-alt",
        color: "#ffffff",
        backgroundColor: "#222222",
        winner: false,
        stock: 1,
      },
      {
        id: "premio 5",
		title: "¡Felicidades!",
		subTitle: "has ganado",
        name: "Paquete de Internet",
        description: "Paquete de Internet",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/nIanBTvxgcA3Cf8TJlRw%2Fimage-original.jpg?alt=media&token=ad728fdc-b20d-4060-b46d-2f43dbb54f9d",
        // icon: "fal fa-mobile",
        color: "#ffffff",
        backgroundColor: "#5170b5",
        winner: true,
        stock: 3,
      },
      {
        id: "premio 6",
        title: "¡Woohoo!",
        subTitle: "Tienes otra oportunidad",
        name: "Intentalo de Nuevo",
        description: "Intentalo de nuevo",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/tbAt6ifoZBdBy2sOwdZF%2Fimage-original.jpg?alt=media&token=90adc167-3375-4c89-92e9-620fb0bf0f4d",
        // icon: "fal fa-redo-alt",
        color: "#ffffff",
        backgroundColor: "#222222",
        winner: false,
        stock: 1,
      },
      {
        id: "premio 7",
        title: "¡Woohoo!",
        subTitle: "Tienes otra oportunidad",
        name: "Intentalo de Nuevo",
        description: "Intentalo de nuevo",
        image: "https://firebasestorage.googleapis.com/v0/b/el-ocho-categories/o/tbAt6ifoZBdBy2sOwdZF%2Fimage-original.jpg?alt=media&token=90adc167-3375-4c89-92e9-620fb0bf0f4d",
        // icon: "fal fa-redo-alt",
        color: "#ffffff",
        backgroundColor: "#222222",
        winner: false,
        stock: 1,
      }
    ]
  },
  mutations: {
    setAllPrizes(state, data) {
      state.prizes = data;
    },
    setPrizes(state, target, data) {
      state.prizes[target] = Object.assign(state.prizes[target], data);
    },
    reducePrizeQty(state, target) {
      if(state.prizes[target].stock > 0){
        state.prizes[target].stock = state.prizes[target].stock - 1;
      }
    },
  },
  actions: {
  },
  modules: {
  },
  // plugins: [vuexPersist.plugin]
})

// get
// this.$store.state.client.email

// set
// this.$store.commit("setPrizes", 4, {
//     id: "premio 6",
//     title: "¡Woohoo!",
// subTitle: "Tienes otra oportunidad",
//     name: "Intentalo de Nuevo",
//     description: "Intentalo de nuevo",
//     image: "img/LogoFury.png",
    // icon: "fal fa-redo-alt",
//     color: "#ffffff",
//     backgroundColor: "#222222",
//     winner: false,
//     stock: -1,
//   });